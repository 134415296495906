.wrapper {
  font-family: 'Fira Sans', monospace;
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 1.5rem;
  color: #12de93;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 0.25em;
  display: none;
  min-width: 110px;
  transition: all 0.2s;
}

.visible {
  display: block;
}

.warning {
  color: #c72924;
}
