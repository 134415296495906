.App {
  text-align: center;
}

section {
  padding: 0.5em;
}

input {
  padding: 0.25em;
}

main {
  padding-top: 3em;
  padding-bottom: 1em;
}

a {
  color: #1ac0c6;
}
