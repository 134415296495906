.header {
  background: #fafafa;
  color: #fafafa;
  position: fixed;
  width: 100%;
  height: 2.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > * {
  min-width: 50px;
}

.title {
  padding: 0 .5em;
  background: none;
  border: none;
  color: #ff6150;
  user-select: none;
}
